<template>
    <CRow>
        <CCol col="12" xl="12">
            <CCard>
                <CCardHeader>Kargo Bekleyen Siparişler</CCardHeader>
                <CCardBody>
                    <CTabs>
                        <CTab title="Sipariş Listesi" active>
                            <CDataTable
                                hover
                                striped
                                :items="orders"
                                :fields="fields"
                                clickable-rows
                                @row-clicked="rowClicked"
                            >
                                <template #fullName="data">
                                    <td>
                                        {{ data.item.author.firstname }} {{ data.item.author.lastname }}
                                    </td>
                                </template>
                                <template #status="data">
                                    <td>
                                        {{ getOrderStatus(data.item.status) }}
                                    </td>
                                </template>
                                <template #paymentMethod="data">
                                    <td>
                                        {{ getPaymentMethod(data.item.paymentMethod) }}
                                    </td>
                                </template>
                                <template #products="data">
                                    <td>
                                        <ul>
                                            <li v-for="(item, index) in data.item.products" :key="index">
                                                {{ item.count }} Adet {{ item.product.name }} - {{ item.size}}
                                            </li>
                                        </ul>
                                    </td>
                                </template>
                                <template #info="data">
                                    <td>
                                        <CBadge v-if="data.item.surveillances && data.item.surveillances.length > 0" :color="'warning'" v-on:click.stop="">
                                            {{ data.item.surveillances.length }} Adet şüpheli hareket
                                        </CBadge>
                                    </td>
                                </template>
                                <template #changeType="data">
                                    <td>
                                        <CButton
                                            block
                                            variant="outline"
                                            color="warning"
                                            v-on:click.stop="changeOrderStatus('rejected', data.item._id)"
                                        >
                                            Iptal Edildi
                                        </CButton>
                                    </td>
                                </template>
                            </CDataTable>
                        </CTab>
                        <CTab title="Kargolanacak Ürünler">
                            <CDataTable
                                hover
                                striped
                                :items="products"
                                :fields="productFields"
                                clickable-rows
                                @row-clicked="rowClicked"
                            />
                        </CTab>
                    </CTabs>
                </CCardBody>
                <CCardFooter>
                    <CRow>
                        <CCol col="6">
                            <CButton
                                block
                                variant="outline"
                                :color="'success'"
                                v-on:click.stop="exportExcel()"
                            >
                                Excel indir
                            </CButton>
                        </CCol>
                        <CCol col="6">
                            <CButton
                                block
                                variant="outline"
                                :color="'success'"
                                v-on:click.stop="markAsSent()"
                            >
                                Kargolandı olarak işaretle
                            </CButton>
                        </CCol>
                    </CRow>
                </CCardFooter>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
import axios from 'axios';

import { getOrderStatus, getPaymentMethod } from '../../utils';


export default {
    name: 'WaitingForShipment',

    data () {
        return {
            alert: {
                isOpen: false,
                color: '',
                message: ''
            },
            orders: [],
            products: [],
            productFields: [
                {
                    key: 'name',
                    label: 'İsim',
                },
                {
                    key: 'size',
                    label: 'Beden',
                },
                {
                    key: 'count',
                    label: 'Adet',
                },
            ],
            fields: [
                {
                    key: 'fullName',
                    label: 'İsim',
                    _classes: 'font-weight-bold'
                },
                {
                    key: 'paymentMethod',
                    label: 'Ödeme yöntemi'
                },
                {
                    key: 'status',
                    label: 'Durumu',
                },
                {
                    key: 'products',
                    label: 'Ürünler',
                },
                {
                    key: 'info',
                    label: 'Bilgilendirme'
                },
                {
                    key: 'changeType',
                    label: 'Sipariş tipini değiştir',
                }
            ],
            page: 1
        }
    },

    methods: {
        getOrderStatus: getOrderStatus,
        getPaymentMethod: getPaymentMethod,

        async loadOrders(headers) {
            const response = await axios({
                method: 'get',
                url: '/orders/ready',
                headers: {
                    'X-Page': this.page,
                    'X-Limit': 125215
                },
            });
            this.orders = response.data.results;
            const products = [];

            for (let order of this.orders) {
                for (let p of order.products) {
                    let index = products.findIndex(x => x.name === p.product.name && x.size === p.size);
                    if (index === -1) {
                        products.push({
                            name: p.product.name,
                            size: p.size,
                            count: 0
                        });
                        index = products.length - 1;
                    }

                    products[index].count += p.count;
                }
            }

            this.products = products;
        },

        rowClicked (item) {
            this.$router.push({ path: `${item._id}` });
        },

        async changeOrderStatus(status, orderId) {
            const response = await axios({
                method: 'put',
                url: `/orders/${orderId}/status`,
                data: { status }
            });

            if (response && response.status === 200) {
                await this.loadOrders();
            }
        },

        async markAsSent() {
            await Promise.all(this.orders.map(async(item) => {
                await this.changeOrderStatus('sent', item._id);
            }));
        },

        async exportExcel() {
            const response = await axios({
                url: '/orders/excel',
                method: 'get',
                responseType: 'blob',
            });

            const FILE = window.URL.createObjectURL(new Blob([response.data], {
                type: 'application/vnd.ms-excel',
                
            }));
            window.open(FILE, 'blank');
            window.URL.revokeObjectURL(FILE);
        }
    },

    mounted() {
        this.loadOrders();
    }
}
</script>
